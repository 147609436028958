import { useDispatch, useSelector } from 'react-redux';
import Session from '../pages/Session';
import SessionView from '../pages/SessionView';
import Builder from '../pages/Builder';
import Config from '../pages/Config';
import ListSession from '../pages/ListSession';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Createbuilder from '../pages/CreateBuilder';
import Login from '../pages/Login';

const Layout = () => {

    const state = useSelector(state => state)
    const dispatch = useDispatch();

    return (
        <div className="layout-page">
            <BrowserRouter>
                <Routes>
                    <Route exact path='/' element={<div></div>}></Route>
                    {/*<Route exact path='/login' element={<Login />}></Route>*/}
                    <Route exact path='/sessions' element={<Session />}></Route>
                    <Route exact path='/build' element={<Builder />}></Route>
                    <Route exact path='/session/:sessionId' element={<SessionView />}></Route>
                    <Route exact path='/config' element={<Config />}></Route>
                    <Route exact path='/build/create' element={<Createbuilder update={false} />}></Route>
                    <Route exact path='/all' element={<ListSession />}></Route>
                    <Route exact path='/build/edit/:buildId' element={<Createbuilder update={true} />}></Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default Layout;
