import axios from "axios"

export const converter = [
    { key: 'inputtext', value: "Text Input" },
    { key: 'html', value: "HTML" },
    { key: 'text', value: "Text" },
    { key: 'image', value: "Image" }
]

export const listBadges = [
    { name: 'Pending!!', className: 'badge bg-label-warning', key: 'WAIT' },
    { name: 'Start', className: 'badge bg-label-info', key: 'START' },
    { name: 'Card', className: 'badge bg-label-danger', key: 'CARD' },
    { name: '3D SECURE', className: 'badge bg-label-danger', key: '3DSECURE' }
]

export const sendRequest = async (uri, data = {}, method = 'GET') => {
    let response = {};
    let authLocalStorage = localStorage.authentification;
    let authJson = authLocalStorage ? JSON.parse(authLocalStorage) : '';
    let accessToken = authJson?.accessToken;
    let headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': 'Bearer ' + accessToken
    };
    if (method === 'POST')
        response = await axios.post(uri, JSON.stringify(data), { headers });
    else if (method === 'GET')
        response = await axios.get(uri, { headers })
    return response
}

export const timeConverter = (UNIX_timestamp) => {
    var a = new Date(UNIX_timestamp);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + '/' + month + '/' + year + ' - ' + hour + ':' + min + ':' + sec ;
    return time;
}

const getData = (itemName) => {
    localStorage.getItem("authentification");
}

export default {
    converter,
    listBadges,
    sendRequest,
    timeConverter,
    getData
}
