

const initialState = {
    currentPage: "DASHBOARD",
    endpoint_uri: {
        listSessions: "https://iasdoiaosd.ngrok.io/session/list",
        allSessions: "https://iasdoiaosd.ngrok.io/session/all",
        updateConfigSession: "https://iasdoiaosd.ngrok.io/session/[-reqId-]/setsecure",
        sessionStateUpdate: "https://iasdoiaosd.ngrok.io/session/[-reqId-]/updateState",
        sessionState: 'https://iasdoiaosd.ngrok.io/session/[-reqId-]/state',
        sessionConfig: 'https://iasdoiaosd.ngrok.io/session/[-reqId-]/secure',
        sessionGetHistory: 'https://iasdoiaosd.ngrok.io/session/[-reqId-]/getStore',
        configuration: 'https://iasdoiaosd.ngrok.io/config',
        configurationUpdate: 'https://iasdoiaosd.ngrok.io/config/update',
        createBuilder: 'https://iasdoiaosd.ngrok.io/build/create',
        listConfigs: 'https://iasdoiaosd.ngrok.io/build/list',
        getBuilder: 'https://iasdoiaosd.ngrok.io/build/get',
        updateBuilder: 'https://iasdoiaosd.ngrok.io/build/update',
        deleteBuilder: 'https://iasdoiaosd.ngrok.io/build/delete',
        loginPage: 'https://iasdoiaosd.ngrok.io/login',
        ping: 'https://iasdoiaosd.ngrok.io/ping',
    },
    listSessions: [],
    selectedSession: "",
    componentBuilder: [],
    history: [],
    configuration: [],
    buildName: '',
    listConfigs: [],
    listOptions: [],
    selectedLoadBuilder: 0,
    imagebbSettings: {
        endpoint: 'https://api.imgbb.com/1/upload?expiration=86400&key=[-YOUR_CLIENT_API_KEY-]',
        privateKey: '9f9eac6f4b216103bb02115863c1a54a'
    },
    imageUploadURL: 'This text will replaced automatically when image uploaded.'
}

function updateCurrentPage (state, payload) {
    return { ...state, currentPage: payload }
}

function setListSessions(state, payload) {
    return { ...state, listSessions: payload }
}

function setSelectedSession(state, payload) {
    return { ...state, selectedSession: payload }
}

function addInputText(state, payload) {
    let comp = [...state.componentBuilder, payload]
    return { ...state, componentBuilder: comp }
}

function deleteComponent(state, payload) {
    let newComponentBuilder = state.componentBuilder.filter((item, index) => index !== payload)
    return { ...state, componentBuilder: newComponentBuilder }
}

function updateTextLabel(state, payload){
    let componentBuilder = state.componentBuilder.map((item, key) => {
        if (key === payload.key) {
            item = { ...item, section: { ...item.section, label: payload.value } }
        }
        return item
    })
    return { ...state, componentBuilder }
}

function updateTextPholder(state, payload){
    let componentBuilder = state.componentBuilder.map((item, key) => {
        if (key === payload.key) {
            item = { ...item, section: { ...item.section, placeholder: payload.value } }
        }
        return item
    })
    return { ...state, componentBuilder }
}

function updateImageURL(state, payload){
    let componentBuilder = state.componentBuilder.map((item, key) => {
        if (key === payload.key) {
            item = { ...item, section: { ...item.section, url: payload.value } }
        }
        return item
    })
    return { ...state, componentBuilder }
}

function updateImageWIDTH(state, payload){
    let componentBuilder = state.componentBuilder.map((item, key) => {
        if (key === payload.key) {
            item = { ...item, section: { ...item.section, width: payload.value } }
        }
        return item
    })
    return { ...state, componentBuilder }
}

function updateHTMLCONTENT(state, payload) {
    let componentBuilder = state.componentBuilder.map((item, key) => {
        if (key === payload.key) {
            item = { ...item, section: { ...item.section, __html: payload.value } }
        }
        return item
    })
    return { ...state, componentBuilder }
}

function updateTEXTCONTENT(state, payload) {
    let componentBuilder = state.componentBuilder.map((item, key) => {
        if (key === payload.key) {
            item = { ...item, section: { ...item.section, text: payload.value } }
        }
        return item
    })
    return { ...state, componentBuilder }
}

function setConfig (state, payload) {
    return { ...state, componentBuilder: payload }
}

function setHistory (state, payload) {
    return { ...state, history: payload }
}

function setConfiguration(state, payload) {
    return { ...state, configuration: payload }
}

function updateConfiguration(state, payload){
    let configuration = state.configuration.map((item, key) => {
        if (item.key == payload.key) {
            item = { ...item, value: payload.value }
        }
        return item
    })
    return { ...state, configuration }
}

function updateBuildName(state, payload) {
    return { ...state, buildName: payload }
}

function updateListConfigs(state, payload) {
    return { ...state, listConfigs: payload }
}

function updateOptions(state, payload) {
    let listOptions = payload.map((item) => {
        return { value: item._id, label: item.name }
    })
    return { ...state, listOptions: listOptions };
}

function updateSelectedLoadBuilder(state, payload) {
    return { ...state, selectedLoadBuilder: payload };
}

function updateImageUploadURL(state, payload) {
    return { ...state, imageUploadURL: payload }
}

function updateComponentPosition(state, payload) {
    return { ...state, componentBuilder: payload };
}
 
export function reducer(state = initialState, action) {
    switch (action.type){
        case 'UPDATE_PAGE':
            return updateCurrentPage(state, action.payload);
        case 'SET_SESSIONS':
            return setListSessions(state, action.payload);
        case 'SET_SELECTED_SESSION':
            return setSelectedSession(state, action.payload);
        case 'ADD_INPUT_TEXT':
            return addInputText(state, action.payload);
        case 'ADD_HTML':
            return addInputText(state, action.payload);
        case 'ADD_IMAGE':
            return addInputText(state, action.payload);
        case 'ADD_TEXT':
            return addInputText(state, action.payload);
        case 'DELETE_COMPONENT':
            return deleteComponent(state, action.payload);
        case 'UPDATE_TEXT_LABEL':
            return updateTextLabel(state, action.payload);
        case 'UPDATE_TEXT_PHOLDER':
            return updateTextPholder(state, action.payload);
        case 'UPDATE_IMAGE_URL':
            return updateImageURL(state, action.payload);
        case 'UPDATE_IMAGE_WIDTH':
            return updateImageWIDTH(state, action.payload);
        case 'UPDATE_HTML_CONTENT':
            return updateHTMLCONTENT(state, action.payload);
        case 'UPDATE_TEXT_CONTENT':
            return updateTEXTCONTENT(state, action.payload);
        case 'SET_CONFIG':
            return setConfig(state, action.payload);
        case 'SET_HISTORY':
            return setHistory(state, action.payload);
        case 'SET_CONFIGURATION':
            return setConfiguration(state, action.payload);
        case 'UPDATE_CONFIGURATION':
            return updateConfiguration(state, action.payload);
        case 'UPDATE_BUILDNAME':
            return updateBuildName(state, action.payload);
        case 'UPDATE_LISTCONFIGS':
            return updateListConfigs(state, action.payload);
        case 'UPDATE_OPTIONS':
            return updateOptions(state, action.payload);
        case 'UPDATE_LOADBUILDER':
            return updateSelectedLoadBuilder(state, action.payload);
        case 'UPDATE_IMAGEUPLOAD':
            return updateImageUploadURL(state, action.payload);
        case 'UPDATE_COMPONENT_POSITION':
            return updateComponentPosition(state, action.payload);
        default:
            return state;
    }
}

export default {reducer};
