import { useDispatch, useSelector } from 'react-redux';

const SessionBuildViewer = () => {

    const state = useSelector(state => state)
    const dispatch = useDispatch()

    const joinReactComponentsIfExistWithSeparator = (arrayOfComponents, joiner) => arrayOfComponents.filter(Boolean).map((item, index) =>
    <span key={index}>{index !== 0 && joiner}{item}</span>);

    const inputText = (section) => {
        return (
            <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="exampleInputEmail1">{section.label}</label>
                <input type="text" className="form-control" id="exampleInputEmail1" placeholder={section.placeholder} onChange={ (e) => { dispatch({ type: 'UPDATE_SECURE', payload: { key: section.label, value: e.target.value } }) } } />
            </div>
        );
    }

    const renderText = (section) => {
        let content = { __html: section.text }
        return (
            <div className="form-group">
                <div dangerouslySetInnerHTML={content} />
            </div>
        );
    }

    const renderHTML = (section) => {
        return (
            <div><div dangerouslySetInnerHTML={section} /></div>
        );
    }

    const renderIMAGE = (section) => {
        return (
            <div className="form-group">
                <center><img src={section.url} width={section.width} /></center>
            </div>
        );
    }

    const renderConfig = () => {
        let components = []
        if (state.componentBuilder?.length) {
            for (let i = 0; i < state.componentBuilder.length; i++) {
                let item = state.componentBuilder[i]
                if (item["key"] === 'inputtext') {
                    components.push(inputText(item['section']));
                } else if (item["key"] === 'html') {
                    components.push(renderHTML(item['section']));
                } else if (item["key"] === 'image') {
                    components.push(renderIMAGE(item['section']));
                } else if (item["key"] === 'text') {
                    components.push(renderText(item['section']));
                }
            }
        }
        return joinReactComponentsIfExistWithSeparator(components)
    }

    return (
        <div>
            { renderConfig() }
        </div>
    );
}

export default SessionBuildViewer;
