import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    converter,
    listBadges,
    sendRequest,
    timeConverter
} from './../constants/Constants'

const Session = () => {

    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const getListSession = async () => {
        const response = await sendRequest(state.endpoint_uri.listSessions, {}, 'GET');
        if (response.status) {
            dispatch( { type: "SET_SESSIONS", payload: response.data } )
        }
    }

    const destroyComponent = ({ intervalId }) => {
        if (intervalId)
            clearInterval(intervalId);
        
        dispatch( { type: 'SET_SESSIONS', payload: [] } )
    }

    const renderListSession = () => {
        let pendingSession = state.listSessions.filter((item, index) => item.currentState === 'WAIT');
        let nonPendingSession = state.listSessions.filter((item, index) => item.currentState !== 'WAIT');
        let data = pendingSession.concat(nonPendingSession);
        return data.map((item, key) => {
            return renderContent(item, key)
        });
    }

    useEffect( () => {
        const intervalId = setInterval(getListSession, 1000)
        return () => destroyComponent( { intervalId } );
    }, [])

    const listBadges = [
        { name: 'Pending!!', className: 'badge bg-label-warning', key: 'WAIT' },
        { name: 'Start', className: 'badge bg-label-info', key: 'START' },
        { name: 'Card', className: 'badge bg-label-danger', key: 'CARD' },
        { name: '3D SECURE', className: 'badge bg-label-danger', key: '3DSECURE' }
    ]

    const renderBadge = (item) => {
        return listBadges.map((badge, key) => {
            if (item.currentState === badge.key)
                return (<span key={key} className={badge.className}>{badge.name}</span>);
        })
    }

    const updateSelectedSession = (item) => {
        dispatch({ type: "SET_SELECTED_SESSION", payload: item })
        dispatch({ type: "UPDATE_PAGE", payload: "SESSION_VIEWER" })
    }

    const renderContent = (item, key) => {
        return (
            <tr /*onClick={ () => updateSelectedSession(item) }*/ key={key} style={item.currentState === "WAIT" ? { backgroundColor: "#B8EFC0" } : {}}>
                <td>
                    <i className="fab fa-angular fa-lg text-danger me-3" />
                    <strong><a href={ "/session/" + item.reqId }>{ item.reqId }</a></strong>
                </td>
                <td>
                    { timeConverter(item.online_at) }
                </td>
                <td>
                    { renderBadge(item) }
                </td>
            </tr>
        )
    }

    return (
        <div className='container-xxl flex-grow-1 container-p-y'>
            <div className="card">
                <h5 className="card-header">Live Session: { state.listSessions.length }</h5>
                <div className="table-responsive text-nowrap">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Session ID</th>
                                <th>Online AT</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            { renderListSession() }
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
}

export default Session;
