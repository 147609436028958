import { useSelector } from 'react-redux';
import { sendRequest } from '../constants/Constants';
import { useEffect, useState } from 'react';

const Login = () => {

    const state = useSelector(state => state);
    /*const dispatch = useDispatch();*/
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [loginComponent, setLoginCompoenent] = useState(true);

    const checkLogin =  async () => {
        let authentication = localStorage.getItem('authentification');
        if (authentication.length >= 10) {
            let uri = state.endpoint_uri.ping;
            try {
                let response = await sendRequest(uri, {}, 'GET');
                if (response.status === 200) {
                    setLoginCompoenent(false);
                } else {
                    setLoginCompoenent(true);
                }
            } catch(e) {
                console.log('Error while checking Login!');
            }

        } else {
            setLoginCompoenent(true);
        }
    }

    const useEffectCallback = () => {
        checkLogin();
    }

    //useEffect(useEffectCallback, []);

    const saveOnLocalStorage = (dataJson) => {
        localStorage.setItem("authentification", dataJson);   
    }

    const makeLogin = async () => {
        let data = { username, password };
        let uri = state.endpoint_uri.loginPage;
        let response = await sendRequest(uri, data, "POST");
        if (response.status === 200) {
            let dataToLocalStorage = JSON.stringify(response.data);
            saveOnLocalStorage(dataToLocalStorage);
            window.location.href = '/sessions'
        } else {
            console.log("Error while Login");
        }
    }

    const loginComp = () => {
        return (
            <div className="authentication-wrapper authentication-basic container-p-y" style={{ display: "flex", minHeight: "100vh", width: "100%", justifyContent: "center", alignItems: "center" }}>
                <div className="authentication-inner" style={{ maxWidth: "450px" }}>
                <div className="card">
                    <div className="card-body">
                    {/* /Logo */}
                    <h4 className="mb-2">Welcome to Sneat! 👋</h4>
                    <p className="mb-4">
                        Please sign-in to your account and start the adventure
                    </p>
                    <div
                        id="formAuthentication"
                        className="mb-3"
                    >
                        <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                            Email or Username
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="email"
                            name="email-username"
                            placeholder="Enter your email or username"
                            autoFocus=""
                            onChange={ (e) => setUsername(e.target.value) }
                        />
                        </div>
                        <div className="mb-3 form-password-toggle">
                        <div className="d-flex justify-content-between">
                            <label className="form-label" htmlFor="password">
                            Password
                            </label>
                            <a href="auth-forgot-password-basic.html">
                            <small>Forgot Password?</small>
                            </a>
                        </div>
                        <div className="input-group input-group-merge">
                            <input
                            type="password"
                            id="password"
                            className="form-control"
                            name="password"
                            placeholder="············"
                            aria-describedby="password"
                            onChange={ (e) => setPassword(e.target.value) }
                            />
                            <span className="input-group-text cursor-pointer">
                            <i className="bx bx-hide" />
                            </span>
                        </div>
                        </div>
                        
                        <div className="mb-3">
                            <button className="btn btn-primary d-grid w-100" onClick={ makeLogin }>
                                Sign in
                            </button>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }

    return (
        <div className='container-xxl flex-grow-1 container-p-y'>
            <div className="container-xxl" style={{ height: '100%' }}>
                {
                    loginComponent ? 
                        loginComp()
                        : 
                        <div style={{ fontSize: 25, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            You are logged in!
                        </div>
                }
            </div>
        </div>
    );
}

export default Login;
