import { useDispatch, useSelector } from 'react-redux';
import SessionBuildViewer from './SessionBuildViewer';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import FullEditor from "ckeditor5-build-full";
import { useEffect } from 'react';
import Select from 'react-select';
import axios from "axios"

import {
    converter,
    listBadges,
    sendRequest,
    timeConverter
} from './../constants/Constants'
import { useParams } from 'react-router-dom';

const SessionView = (props) => {

    const state = useSelector(state => state)
    const dispatch = useDispatch();
    const { sessionId } = useParams();

    let fetchStateStatus = async () => {
        let uri = state.endpoint_uri.sessionState.replace('[-reqId-]', sessionId)
        let response = await sendRequest(uri)
        if (response.status)
            dispatch({ type: 'SET_SELECTED_SESSION', payload: response.data })

        uri = state.endpoint_uri.sessionGetHistory.replace('[-reqId-]', sessionId)
        response = await sendRequest(uri)
        if (response.status)
            dispatch({ type: 'SET_HISTORY', payload: response.data })
    }

    let getLastSessionConfig = async () => {
        let uri = state.endpoint_uri.sessionConfig.replace('[-reqId-]', sessionId);
        let response = await sendRequest(uri);
        if (response.status)
            dispatch({ type: 'SET_CONFIG', payload: response.data.config })
    }

    let destroyComponent = ({ intervalId }) => {
        if (intervalId)
            clearInterval(intervalId)

        dispatch({ type: 'SET_CONFIG', payload: [] })
        dispatch({ type: 'SET_HISTORY',payload: [] })
        dispatch({ type: 'SET_SELECTED_SESSION',payload: "" })
    }

    let getListConfigs = async () => {
        let uri = state.endpoint_uri.listConfigs;
        let response = await sendRequest(uri, {}, "GET");
        if (response.status) {
            dispatch({ type: "UPDATE_LISTCONFIGS", payload: response.data?.data });
            dispatch({ type: 'UPDATE_OPTIONS', payload: response.data?.data })
        }
    }

    let GetPanelConfiguration = async () => {
        let uri = state.endpoint_uri.configuration;
        let response = await sendRequest(uri, { }, 'POST');
        if (response.status) 
            dispatch({ type: 'SET_CONFIGURATION', payload: response.data })
    }

    useEffect(() => {
        let intervalId = setInterval(fetchStateStatus, 2000);
        fetchStateStatus();
        getLastSessionConfig();
        getListConfigs();
        GetPanelConfiguration();
        return () => destroyComponent({ intervalId })
    }, [])

    const renderBadge = (item) => {
        return listBadges.map((badge, key) => {
            if (item.currentState === badge.key)
                return (<span key={key} className={badge.className}>{badge.name}</span>);
        })
    }

    const deleteComponent = (key) => {
        dispatch({ type: 'DELETE_COMPONENT', payload: key })
    }

    const textInputFields = (item, key) => {
        return (
            <div className="row mb-3">
                <label className="col-sm-3 col-form-label" htmlFor="basic-default-name">
                    Label
                </label>
                <div className="col-sm-9" style={{ marginBottom: 5 }}>
                    <input
                        onChange={(e) => { dispatch({ type: "UPDATE_TEXT_LABEL", payload: { key: key, value: e.target.value } }) }}
                        type="text"
                        className="form-control"
                        id="basic-default-name"
                        placeholder="John Doe"
                        fdprocessedid="bu8pnm"
                        value={ item.section.label }
                    />
                </div>
                <label className="col-sm-3 col-form-label" htmlFor="basic-default-name">
                    Placeholder
                </label>
                <div className="col-sm-9">
                    <input
                        onChange={(e) => { dispatch({ type: "UPDATE_TEXT_PHOLDER", payload: { key: key, value: e.target.value } }) }}
                        type="text"
                        className="form-control"
                        id="basic-default-name"
                        placeholder="John Doe"
                        fdprocessedid="bu8pnm"
                        value={ item.section.placeholder }
                    />
                </div>
            </div>

        );
    }

    const imageField = (item, key) => {
        return (
            <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="basic-default-name">
                    URL
                </label>
                <div className="col-sm-10" style={{ marginBottom: 5 }}>
                    <input
                        onChange={(e) => { dispatch({ type: "UPDATE_IMAGE_URL", payload: { key: key, value: e.target.value } }) }}
                        type="text"
                        className="form-control"
                        id="basic-default-name"
                        placeholder="John Doe"
                        fdprocessedid="bu8pnm"
                        value={ item.section.url }
                    />
                </div>
                <label className="col-sm-2 col-form-label" htmlFor="basic-default-name">
                    WIDTH
                </label>
                <div className="col-sm-10">
                    <input
                        onChange={(e) => { dispatch({ type: "UPDATE_IMAGE_WIDTH", payload: { key: key, value: e.target.value } }) }}
                        type="text"
                        className="form-control"
                        id="basic-default-name"
                        placeholder="John Doe"
                        fdprocessedid="bu8pnm"
                        value={ item.section.width }
                    />
                </div>
            </div>

        );
    }

    const htmlFields = (item, key) => {
        return (
            <div>
                <label htmlFor="exampleFormControlTextarea1" className="form-label">
                    CKEditor
                </label>
                
                <CKEditor
                    editor={ FullEditor }
                    data={ item.section.__html }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        dispatch({ type: "UPDATE_HTML_CONTENT", payload: { key: key, value: data } })
                    } }
                    config={{
                        toolbar: {
                          items: [
                            "heading",
                            "|",
                            "alignment",
                            "|",
                            "bold",
                            "italic",
                            "strikethrough",
                            "underline",
                            "subscript",
                            "superscript",
                            "|",
                            "link",
                            "|",
                            "bulletedList",
                            "numberedList",
                            "todoList",
                            "-", // break point
                            "fontfamily",
                            "fontsize",
                            "fontColor",
                            "fontBackgroundColor",
                            "|",
                            "code",
                            "codeBlock",
                            "|",
                            "insertTable",
                            "|",
                            "outdent",
                            "indent",
                            "|",
                            "uploadImage",
                            "blockQuote",
                            "|",
                            "undo",
                            "redo",
                            "sourceEditing"
                          ],
                          shouldNotGroupWhenFull: true
                        }
                      }}
                />
            </div>

        );
    }

    const textField = (item, key) => {
        return (
            <div>
                <label htmlFor="exampleFormControlTextarea1" className="form-label">
                    HTML source
                </label>
                <textarea
                    onChange={(e) => { dispatch({ type: "UPDATE_TEXT_CONTENT", payload: { key: key, value: e.target.value } }) }}
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    defaultValue={ item.section.text }
                />
            </div>

        );
    }

    const renderFields = (item, key) => {
        switch (item.key) {
            case 'inputtext':
                return textInputFields(item, key);
            case 'html':
                return htmlFields(item, key);
            case 'image':
                return imageField(item, key);
            case 'text':
                return textField(item, key);
        }
    }

    const updatePosition =(arr, pos1, pos2) => {
        // local variables
        var i, tmp;
        // cast input parameters to integers
        pos1 = parseInt(pos1, 10);
        pos2 = parseInt(pos2, 10);
        // if positions are different and inside array
        if (pos1 !== pos2 && 0 <= pos1 && pos1 <= arr.length && 0 <= pos2 && pos2 <= arr.length) {
            // save element from position 1
            tmp = arr[pos1];
            // move element down and shift other elements up
            if (pos1 < pos2) {
                for (i = pos1; i < pos2; i++) {
                arr[i] = arr[i + 1];
                }
            }
            // move element up and shift other elements down
            else {
                for (i = pos1; i > pos2; i--) {
                arr[i] = arr[i - 1];
                }
            }
            // put element from position 1 to destination
            arr[pos2] = tmp;
        }
        return arr;
    }

    const updateComponentPosition = (key, action) => {
        if (action == 'down') {
            if ((key + 1) < state.componentBuilder.length) {
                let componentBuilder = state.componentBuilder.slice();
                componentBuilder = updatePosition(componentBuilder, key, key + 1);
                dispatch({ type: "UPDATE_COMPONENT_POSITION", payload: componentBuilder })
            }
        } else if (action == 'up') {
            if ((key - 1) >= 0) {
                let componentBuilder = state.componentBuilder.slice();
                componentBuilder = updatePosition(componentBuilder, key, key - 1);
                dispatch({ type: "UPDATE_COMPONENT_POSITION", payload: componentBuilder })
            }
        }
    }

    const renderComponents = () => {
        return (
            state.componentBuilder.map((item, key) => {
                return (
                    <div className="card accordion-item active" key={key} style={{ marginBottom: 10, boxShadow: "none", border: "1px solid #ccc" }}>
                        <h2 className="accordion-header" id="heading">
                            <button
                                type="button"
                                className="accordion-button"
                                data-bs-toggle="collapse"
                                data-bs-target={'#__' + key}
                                aria-expanded="true"
                                aria-controls="accordionOne"
                            >
                                {converter.map((v) => {
                                    if (v.key === item?.key)
                                        return v.value
                                })}
                            </button>
                        </h2>
                        <div
                            id={'__' + key}
                            className="accordion-collapse collapse show"
                            data-bs-parent="#accordionExample"
                            style={{}}
                        >
                            <div className="accordion-body">
                                {renderFields(item, key)}
                                <div style={{ marginTop: 20, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <div>
                                        <button onClick={() => { deleteComponent(key) }} type="button" className="btn rounded-pill btn-outline-danger" fdprocessedid="luz8hj">X</button>
                                    </div>
                                    <div>
                                        <button onClick={() => { updateComponentPosition(key, 'up') }} type="button" style={{ marginRight: 5 }} className="btn rounded-pill btn-outline-warning" fdprocessedid="luz8hj">UP</button>
                                        <button onClick={() => { updateComponentPosition(key, 'down') }} type="button" className="btn rounded-pill btn-outline-warning" fdprocessedid="luz8hj">DOWN</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })
        );
    }

    const addTextInput = () => {
        const exp = {
            section: {
                label: "",
                placeholder: ""
            },
            key: "inputtext"
        }
        dispatch({ type: "ADD_INPUT_TEXT", payload: exp })
    }

    const addHtml = () => {
        const exp = {
            section: {
                __html: "",
            },
            key: "html"
        }
        dispatch({ type: "ADD_HTML", payload: exp })
    }

    const addImage = () => {
        const exp = {
            section: {
                url: "",
                width: ""
            },
            key: "image"
        }
        dispatch({ type: "ADD_IMAGE", payload: exp })
    }

    const addText = () => {
        const exp = {
            section: {
                text: "",
            },
            key: "text"
        }
        dispatch({ type: "ADD_TEXT", payload: exp })
    }

    const addObject = (key) => {
        switch (key) {
            case 'inputtext':
                addTextInput();
                break;
            case 'html':
                addHtml();
                break;
            case 'image':
                addImage();
                break;
            case 'text':
                addText();
                break;
        }
    }


    const updateConfig = async (payload) => {
        let uri = state.endpoint_uri.updateConfigSession.replace('[-reqId-]', sessionId);
        return await sendRequest(uri, payload, 'POST')
    }

    const updateState = async (payload) => {
        let uri = state.endpoint_uri.sessionStateUpdate.replace('[-reqId-]', sessionId);
        return await sendRequest(uri, payload, 'POST')
    }

    const submitComponents = async (action) => {
        switch (action.type) {
            case 'UPDATE_CONFIG':
                await updateConfig(action.payload);
                return await updateState({ newState: '3DSECURE' });
            case 'UPDATE_STATE_WAIT':
                return await updateState(action.payload)
        }
    }

    const convertJsonKeys = (key) => {
        switch (key) {
            case "cardN":
                return "Card number";
            case "cardE":
                return "Card expiration";
            case "cardC":
                return "CVV or CVC";
            case "cardH":
                return "Card Holder Name";
            default:
                return key;
        }
    } 

    const jsonStringify = (jsonContent, type) => {
        if (type == "card") {
            return (
                <ul>
                    { Object.keys(jsonContent).map((item, key)=> (<li style={{ margin: 0 }} key={key}><b>{ convertJsonKeys(item) }</b>: {jsonContent[item]}</li>)) }
                </ul>
            );
        } else if (type == "secure") {
            return (
                <ul>
                    { jsonContent.map((item, key)=> (<li style={{ margin: 0 }} key={key}><b>{ item?.key }</b>: {item?.value}</li>)) }
                </ul>
            );
        }
    }

    const listStateRender = (item, key, created_at) => {
        let time = timeConverter(created_at)

        switch (item.sessionInfo.currentState){
            case 'CARD':
                return (<div key={key}><b style={{  }}>State (CARD) - </b>Date: {time}<br/>{ jsonStringify(item.cardInfo, "card") }</div>)
            case '3DSECURE':
                return (<div style={{ marginBottom: 10 }} key={key}><b style={{  }}>3DSECURE: </b>{time}<br/>{ jsonStringify(item.secure, "secure") }</div>)
        }
    }

    const sessionFingerPrint = (fingerPrintObject) => {
        if (fingerPrintObject?.reduxStore) {
            let reduxStore = JSON.parse(fingerPrintObject?.reduxStore);
            let objectToPrint = { 
                ipAddress: reduxStore?.ipinfo?.ip,
                countryName: reduxStore?.ipinfo?.country_name,
                currencyCode: reduxStore?.ipinfo?.currency,
                region: reduxStore?.ipinfo?.region,
                userAgent: reduxStore?.fingerPrints?.userAgent,
                navigatorPlatform: reduxStore?.fingerPrints?.navigatorPlatform,
                navigatorLanguage: reduxStore?.fingerPrints?.navigatorLanguage,
                graphics_card: reduxStore?.fingerPrints?.graphics_card,
                // order_id: reduxStore?.fingerPrints?.order_id
            }
            return Object.keys(objectToPrint).map((item, key) => {
                return (<div key={key}><b>{ item }: </b> { objectToPrint[item] } </div>)
            })
        }
    }

    const updateSelectedComponent = () => {
        let builderId = state.selectedLoadBuilder;
        console.log(builderId);
        let componentConfig = state.listConfigs.filter((item) => item._id === builderId);
        dispatch( { type: 'SET_CONFIG', payload: JSON.parse(componentConfig[0]?.config) } );
    }

    const loadBuilder = () => {
        return (
            <div>
                <Select
                    options={state.listOptions}
                    onChange={(e) => dispatch( { type: 'UPDATE_LOADBUILDER', payload: e.value } ) }
                />
                <div className="text-end" style={{ padding: '5px 0px' }}>
                    <div>
                        <button type="button" className="btn btn-outline-primary" onClick={ updateSelectedComponent }>Load</button>
                    </div>
                </div>
            </div>
        );
    }

    const uploadImage = async (event) => {
        let getImgBBKey = state.configuration.filter((item) => item.key == 'imgbbToken');
        let uri = state.imagebbSettings.endpoint.replace('[-YOUR_CLIENT_API_KEY-]', getImgBBKey[0].value);
        const file = event.target.files[0];
        // create a new FormData object and append the file to it
        const formData = new FormData();
        formData.append("image", file);

        let response = await axios.post(uri, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        if (response.status === 200) {
            dispatch({ type: 'UPDATE_IMAGEUPLOAD', payload: response.data.data.url });
        }
    }

    return (
        <div>
            <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">
                    Session Id: {sessionId} {renderBadge(state.selectedSession)}
                </h4>
                {/* Basic Layout */}
                <div className="row">
                    <div className="col-xl">
                        <div className="card mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Load template</h5>
                            </div>
                            <div className="card-body">
                                { loadBuilder() }
                            </div>
                        </div>
                        <div className="card mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Components</h5>
                            </div>
                            <div className="card-body">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <button onClick={() => { addObject('inputtext') }} type="button" className="btn btn-outline-primary">Text Input</button>
                                    <button onClick={() => { addObject('html') }} type="button" className="btn btn-outline-primary">CKEditor</button>
                                    <button onClick={() => { addObject('image') }} type="button" className="btn btn-outline-primary">Image</button>
                                    <button onClick={() => { addObject('text') }} type="button" className="btn btn-outline-primary">HTML Source</button>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    {renderComponents()}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>
                                        <button onClick={ () => { submitComponents({ type: 'UPDATE_STATE_WAIT', payload: { newState: 'WAIT' } }) } } type="button" className="btn btn-outline-danger" fdprocessedid="wlnjaj">WAIT</button>
                                    </span>
                                    <span>
                                        <button onClick={ () => { submitComponents({ type: 'UPDATE_CONFIG', payload: { config: state.componentBuilder } }) } } type="button" className="btn btn-outline-primary" fdprocessedid="wlnjaj">SEND</button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-xl">
                        <div className="card mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Upload Image</h5>
                            </div>
                            <div className="card-body">
                            <div style={{ padding: "10px 0" }}>
                                URL: { state.imageUploadURL }
                            </div>
                            <div style={{ padding: "10px 0" }}>
                                <input className="form-control" type="file" id="formFile" onChange={ (e) => uploadImage(e) } />
                            </div>
                            </div>
                        </div>
                        <div className="card mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Viewer</h5>
                            </div>
                            <div className="card-body">
                                <SessionBuildViewer />
                            </div>
                        </div>
                        <div className="card mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">History</h5>
                            </div>
                            <div className="card-body">
                                <div className='sidebar-body ps ps--active-y'>
                                    {
                                        state.history.map((item, key) => {
                                            let reduxStore = JSON.parse(item.reduxStore);
                                            return listStateRender(reduxStore, key, item.created_at);
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="card mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Session fingerPrint</h5>
                            </div>
                            <div className="card-body">
                                <div className='sidebar-body ps ps--active-y'>
                                    {
                                        sessionFingerPrint(state.history[0])
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default SessionView;

