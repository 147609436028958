import { useDispatch, useSelector } from 'react-redux';
import { sendRequest } from './components/constants/Constants';
import { useEffect, useState } from 'react';
import Layout from './components/Layout/Layout';
import Menu from './components/Menu/Menu';
import Login from './components/pages/Login';
import './App.css';

const Homepage = () => {
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <Layout />
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
        </div>
    );
}

const App = () => {

    const state = useSelector(state => state);
    const dispatch = useDispatch();


    const [loginComponent, setLoginCompoenent] = useState(false);

    const checkLogin =  async () => {
        let authentication = localStorage.getItem('authentification');
        if (authentication?.length >= 10) {
            let uri = state.endpoint_uri.ping;
            try {
                let response = await sendRequest(uri, {}, 'GET');
                if (response.status === 200) {
                    setLoginCompoenent(false);
                } else {
                    setLoginCompoenent(true);
                }
            } catch(e) {
                console.log('Error while checking Login!');
            }

        } else {
            setLoginCompoenent(true);
        }
    }

    const useEffectCallback = () => {
        checkLogin();
    }

    useEffect(useEffectCallback, []);

    const renderContent = () => {
        console.log(loginComponent);
        if (!loginComponent) {
            return <Homepage />;
        } else {
            return <Login />;
        }
    }

    return renderContent();
}

export default App;
