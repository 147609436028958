import { useDispatch, useSelector } from 'react-redux';
import { sendRequest } from '../constants/Constants';
import { useEffect } from 'react';

const Builder = () => {

    const state = useSelector(state => state)
    const dispatch = useDispatch();

    let getListConfigs = async () => {
        let uri = state.endpoint_uri.listConfigs;
        let response = await sendRequest(uri, {}, 'GET');
        if (response.status) {
            dispatch({ type: 'UPDATE_LISTCONFIGS', payload: response.data.data });
        }
    }

    let deleteBuildId = async (buildId) =>  {
        let uri = state.endpoint_uri.deleteBuilder;
        let response = await sendRequest(uri, { "buildId": buildId }, "POST");
        if (response.status) {
            window.location.href = '/build';
        }
    }

    let renderListConfigs = (item, key) => {
        return (
            <tr key={key}>
                <td>
                    <i className="fab fa-angular fa-lg text-danger me-3" />{" "}
                    <strong>{ item.name }</strong>
                </td>
                <td>
                    <div className="dropdown">
                        <button
                            type="button"
                            className="btn p-0 dropdown-toggle hide-arrow"
                            data-bs-toggle="dropdown"
                        >
                            <i className="bx bx-dots-vertical-rounded" />
                        </button>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" href={ "/build/edit/" + item._id }>
                                <i className="bx bx-edit-alt me-1" /> Edit
                            </a>
                            <a className="dropdown-item" onClick={ () => deleteBuildId(item._id) }>
                                <i className="bx bx-trash me-1" /> Delete
                            </a>
                        </div>
                    </div>
                </td>
            </tr>
        )
    }

    useEffect(() => {
        getListConfigs();
    }, [])

    return (

        <div className='container-xxl flex-grow-1 container-p-y'>
            <div className='card'>
                <div className="card-header header-elements">
                    <h5>Templates</h5>
                    <div className="card-header-elements ms-auto">
                        <a type="button" className="btn btn-outline-primary" href='/build/create'>Create template</a>
                    </div>
                </div>
                <div className="card-body">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            { state.listConfigs.map( (item, key) => renderListConfigs(item, key) ) }
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    );
}

export default Builder;
