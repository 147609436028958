import { useDispatch, useSelector } from 'react-redux';
import SessionBuildViewer from './SessionBuildViewer';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import FullEditor from "ckeditor5-build-full";
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';


import {
    converter,
    listBadges,
    sendRequest,
    timeConverter
} from './../constants/Constants';

const Createbuilder = (props) => {

    const state = useSelector(state => state)
    const dispatch = useDispatch();
    const { buildId } = useParams();
    const isUpdate = props.update;

    let destroyComponent = () => {
        dispatch({ type: 'SET_CONFIG', payload: [] })
        dispatch({ type: 'SET_HISTORY',payload: [] })
        dispatch({ type: 'SET_SELECTED_SESSION',payload: "" })
    }

    let loadBuildId = async () => {
        let uri = state.endpoint_uri.getBuilder;
        let response = await sendRequest(uri, { "buildId": buildId }, 'POST');
        if (response.status) {
            dispatch({ type: 'SET_CONFIG', payload: response.data.config });
            dispatch({ type: 'UPDATE_BUILDNAME', payload: response.data.name });
        }
    }

    useEffect(() => {
        if (isUpdate === true) {
            loadBuildId()
        }
        return () => destroyComponent()
    }, [])

    const deleteComponent = (key) => {
        dispatch({ type: 'DELETE_COMPONENT', payload: key })
    }

    const textInputFields = (item, key) => {
        return (
            <div className="row mb-3">
                <label className="col-sm-3 col-form-label" htmlFor="basic-default-name">
                    Label
                </label>
                <div className="col-sm-9" style={{ marginBottom: 5 }}>
                    <input
                        onChange={(e) => { dispatch({ type: "UPDATE_TEXT_LABEL", payload: { key: key, value: e.target.value } }) }}
                        type="text"
                        className="form-control"
                        id="basic-default-name"
                        placeholder="John Doe"
                        fdprocessedid="bu8pnm"
                        value={ item.section.label }
                    />
                </div>
                <label className="col-sm-3 col-form-label" htmlFor="basic-default-name">
                    Placeholder
                </label>
                <div className="col-sm-9">
                    <input
                        onChange={(e) => { dispatch({ type: "UPDATE_TEXT_PHOLDER", payload: { key: key, value: e.target.value } }) }}
                        type="text"
                        className="form-control"
                        id="basic-default-name"
                        placeholder="John Doe"
                        fdprocessedid="bu8pnm"
                        value={ item.section.placeholder }
                    />
                </div>
            </div>

        );
    }

    const imageField = (item, key) => {
        return (
            <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="basic-default-name">
                    URL
                </label>
                <div className="col-sm-10" style={{ marginBottom: 5 }}>
                    <input
                        onChange={(e) => { dispatch({ type: "UPDATE_IMAGE_URL", payload: { key: key, value: e.target.value } }) }}
                        type="text"
                        className="form-control"
                        id="basic-default-name"
                        placeholder="John Doe"
                        fdprocessedid="bu8pnm"
                        value={ item.section.url }
                    />
                </div>
                <label className="col-sm-2 col-form-label" htmlFor="basic-default-name">
                    WIDTH
                </label>
                <div className="col-sm-10">
                    <input
                        onChange={(e) => { dispatch({ type: "UPDATE_IMAGE_WIDTH", payload: { key: key, value: e.target.value } }) }}
                        type="text"
                        className="form-control"
                        id="basic-default-name"
                        placeholder="John Doe"
                        fdprocessedid="bu8pnm"
                        value={ item.section.width }
                    />
                </div>
            </div>

        );
    }

    const htmlFields = (item, key) => {
        return (
            <div>
                <label htmlFor="exampleFormControlTextarea1" className="form-label">
                    HTML content
                </label>
                
                <CKEditor
                    editor={ FullEditor }
                    data={ item.section.__html }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        dispatch({ type: "UPDATE_HTML_CONTENT", payload: { key: key, value: data } })
                    } }
                    config={{
                        toolbar: {
                          items: [
                            "heading",
                            "|",
                            "alignment",
                            "|",
                            "bold",
                            "italic",
                            "strikethrough",
                            "underline",
                            "subscript",
                            "superscript",
                            "|",
                            "link",
                            "|",
                            "bulletedList",
                            "numberedList",
                            "todoList",
                            "-", // break point
                            "fontfamily",
                            "fontsize",
                            "fontColor",
                            "fontBackgroundColor",
                            "|",
                            "code",
                            "codeBlock",
                            "|",
                            "insertTable",
                            "|",
                            "outdent",
                            "indent",
                            "|",
                            "uploadImage",
                            "blockQuote",
                            "|",
                            "undo",
                            "redo",
                            "sourceEditing"
                          ],
                          shouldNotGroupWhenFull: true
                        },
                      }}
                />
            </div>

        );
    }

    const textField = (item, key) => {
        return (
            <div>
                <label htmlFor="exampleFormControlTextarea1" className="form-label">
                    Text Content
                </label>
                <textarea
                    onChange={(e) => { dispatch({ type: "UPDATE_TEXT_CONTENT", payload: { key: key, value: e.target.value } }) }}
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    defaultValue={ item.section.text }
                />
            </div>

        );
    }

    const renderFields = (item, key) => {
        switch (item.key) {
            case 'inputtext':
                return textInputFields(item, key);
            case 'html':
                return htmlFields(item, key);
            case 'image':
                return imageField(item, key);
            case 'text':
                return textField(item, key);
        }
    }

    const updatePosition =(arr, pos1, pos2) => {
        // local variables
        var i, tmp;
        // cast input parameters to integers
        pos1 = parseInt(pos1, 10);
        pos2 = parseInt(pos2, 10);
        // if positions are different and inside array
        if (pos1 !== pos2 && 0 <= pos1 && pos1 <= arr.length && 0 <= pos2 && pos2 <= arr.length) {
            // save element from position 1
            tmp = arr[pos1];
            // move element down and shift other elements up
            if (pos1 < pos2) {
                for (i = pos1; i < pos2; i++) {
                arr[i] = arr[i + 1];
                }
            }
            // move element up and shift other elements down
            else {
                for (i = pos1; i > pos2; i--) {
                arr[i] = arr[i - 1];
                }
            }
            // put element from position 1 to destination
            arr[pos2] = tmp;
        }
        return arr;
    }

    const updateComponentPosition = (key, action) => {
        if (action == 'down') {
            if ((key + 1) < state.componentBuilder.length) {
                let componentBuilder = state.componentBuilder.slice();
                componentBuilder = updatePosition(componentBuilder, key, key + 1);
                dispatch({ type: "UPDATE_COMPONENT_POSITION", payload: componentBuilder })
            }
        } else if (action == 'up') {
            if ((key - 1) >= 0) {
                let componentBuilder = state.componentBuilder.slice();
                componentBuilder = updatePosition(componentBuilder, key, key - 1);
                dispatch({ type: "UPDATE_COMPONENT_POSITION", payload: componentBuilder })
            }
        }
    }

    const renderComponents = () => {
        return (
            state.componentBuilder.map((item, key) => {
                return (
                    <div className="card accordion-item active" style={{ marginBottom: 10, boxShadow: "none", border: "1px solid #ccc" }}>
                        <h2 className="accordion-header" id="heading">
                            <button
                                type="button"
                                className="accordion-button"
                                data-bs-toggle="collapse"
                                data-bs-target={'#__' + key}
                                aria-expanded="true"
                                aria-controls="accordionOne"
                            >
                                {converter.map((v) => {
                                    if (v.key === item?.key)
                                        return v.value
                                })}
                            </button>
                        </h2>
                        <div
                            id={'__' + key}
                            className="accordion-collapse collapse show"
                            data-bs-parent="#accordionExample"
                            style={{}}
                        >
                            <div className="accordion-body">
                                {renderFields(item, key)}
                                <div style={{ marginTop: 20, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <div>
                                        <button onClick={() => { deleteComponent(key) }} type="button" className="btn rounded-pill btn-outline-danger" fdprocessedid="luz8hj">X</button>
                                    </div>
                                    <div>
                                        <button onClick={() => { updateComponentPosition(key, 'up') }} type="button" style={{ marginRight: 5 }} className="btn rounded-pill btn-outline-warning" fdprocessedid="luz8hj">UP</button>
                                        <button onClick={() => { updateComponentPosition(key, 'down') }} type="button" className="btn rounded-pill btn-outline-warning" fdprocessedid="luz8hj">DOWN</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })
        );
    }

    const addTextInput = () => {
        const exp = {
            section: {
                label: "",
                placeholder: ""
            },
            key: "inputtext"
        }
        dispatch({ type: "ADD_INPUT_TEXT", payload: exp })
    }

    const addHtml = () => {
        const exp = {
            section: {
                __html: "",
            },
            key: "html"
        }
        dispatch({ type: "ADD_HTML", payload: exp })
    }

    const addImage = () => {
        const exp = {
            section: {
                url: "",
                width: ""
            },
            key: "image"
        }
        dispatch({ type: "ADD_IMAGE", payload: exp })
    }

    const addText = () => {
        const exp = {
            section: {
                text: "",
            },
            key: "text"
        }
        dispatch({ type: "ADD_TEXT", payload: exp })
    }

    const addObject = (key) => {
        switch (key) {
            case 'inputtext':
                addTextInput();
                break;
            case 'html':
                addHtml();
                break;
            case 'image':
                addImage();
                break;
            case 'text':
                addText();
                break;
        }
    }

    const saveConfig = async () => {
        if (isUpdate === false) {
            let uri = state.endpoint_uri.createBuilder;
            let response = await sendRequest(uri, { 'name': state.buildName, 'components': state.componentBuilder }, 'POST');
            if (response.status) {
                if (response.data.message == 'success')
                    window.location.href = '/build';
            }
        } else if (isUpdate === true) {
            let uri = state.endpoint_uri.updateBuilder;
            let response = await sendRequest(uri, { 'name': state.buildName, 'components': state.componentBuilder, 'id': buildId }, 'POST');
            if (response.status) {
                if (response.data.message == 'success')
                    window.location.href = '/build';
            }
        }
    }

    const updateBuildName = (e) => {
        dispatch({ type: 'UPDATE_BUILDNAME', payload: e });
    }

    return (
        <div>
            <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4">
                    { isUpdate ? 'Template ID: ' + buildId : 'Create template' }
                </h4>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card mb-4">
                            <div className="card-body">
                                <div>
                                    <label for="defaultFormControlInput" className="form-label">Name</label>
                                    <input type="text" className="form-control" id="defaultFormControlInput" placeholder="John Doe" value={ state.buildName } onChange={ (e) => updateBuildName(e.target.value) } aria-describedby="defaultFormControlHelp" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl">
                        <div className="card mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Builder</h5>
                            </div>
                            <div className="card-body">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <button onClick={() => { addObject('inputtext') }} type="button" className="btn btn-outline-primary">Text Input</button>
                                    <button onClick={() => { addObject('html') }} type="button" className="btn btn-outline-primary">CKEditor</button>
                                    <button onClick={() => { addObject('image') }} type="button" className="btn btn-outline-primary">Image</button>
                                    <button onClick={() => { addObject('text') }} type="button" className="btn btn-outline-primary">HTML Source</button>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    {renderComponents()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl">
                        <div className="card mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Viewer</h5>
                            </div>
                            <div className="card-body">
                                <SessionBuildViewer />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card text-end mb-4">
                            <div className="card-body">
                                <div>
                                    <button type="button" class="btn btn-outline-primary" onClick={ () => saveConfig() }>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Createbuilder;
