import { useDispatch, useSelector } from 'react-redux';
import {
    sendRequest,
} from './../constants/Constants'
import { useEffect, useState } from 'react';

const Config = () => {

    const state = useSelector(state => state)
    const dispatch = useDispatch();
    const [isSuccess, updateSuccess] = useState('hide');

    let GetPanelConfiguration = async () => {
        let uri = state.endpoint_uri.configuration;
        let response = await sendRequest(uri, { }, 'POST');
        if (response.status) 
            dispatch({ type: 'SET_CONFIGURATION', payload: response.data })
    }

    useEffect(() => {
        GetPanelConfiguration();
    }, []);

    let renderInputs = (key, value, name, itemKey) => {
        return (
            <tr key={key}>
                <td>
                    <strong>{ name }</strong>
                </td>
                <td>
                    <input type="text" className="form-control" id="basic-default-name" value={value} onChange={ (e) => { dispatch({ type: 'UPDATE_CONFIGURATION', payload: { key: key, value: e.target.value } }) } } />
                </td>
            </tr>
        )
    }

    let handleSubmit = async () => {
        let uri = state.endpoint_uri.configurationUpdate;
        let response = await sendRequest(uri, { 'data': state.configuration }, 'POST');
        if (response.status) {
            updateSuccess('show');
        }
    }

    return (
        <div className='container-xxl flex-grow-1 container-p-y'>
            <div className='card'>
                <div className="card-header header-elements">
                    <span className=" me-2"><h5>Configuration</h5></span>
                </div>
                <div className="card-body">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            { state.configuration.map((e, itemKey) => renderInputs(e.key, e.value, e.name)) }
                        </tbody>
                    </table>

                </div>
                <div className="card-header header-elements">
                    <div className="card-header-elements ms-auto">
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>SAVE</button>
                    </div>
                </div>
                <div className={ "bs-toast toast toast-placement-ex m-2 fade bg-success top-0 start-0 " + isSuccess } role="alert" aria-live="assertive" aria-atomic="true" data-delay="2000">
                    <div className="toast-header">
                        <i className="bx bx-bell me-2"></i>
                        <div className="me-auto fw-semibold">Success</div>
                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={ () => updateSuccess('hide') }></button>
                    </div>
                    <div className="toast-body">
                        The settings have been updated successfully.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Config;
